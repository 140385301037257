<template>
  <Header />
  <div class="home"> 
    <Category category=2 color="#40C3C2"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Category from '@/components/Category.vue'

export default {
  name: 'Wines',
  components: {
    Header,
    Category
  }
}
</script>
