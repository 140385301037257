<template>
  <Header />
  <div class="home"> 
    <Category category=3 color="#F07167"/>
  </div>
  <img src="../assets/alergeni.png" class="imagine"/>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Category from '@/components/Category.vue'

export default {
  name: 'Breakfast',
  components: {
    Header,
    Category
  }
}
</script>

<style scoped>
  .imagine {
        display:block;
        width: 500px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
</style>