<template>
<router-link class="fas" to="/"><i class="fas fa-arrow-circle-left fa-7x"></i>	
</router-link>
<div class="container">
    <!-- <Button class="btn" @click="toggleAddSub" 
        :text="showAddSub ? 'Inchide' : 'Adauga subcategorie' "
        :color="showAddSub ? '#f07167' : '#a1c181' "/>
    <AddSub v-show="showAddSub" :category="category" /> -->
    <div v-for="subcategory in subcategories" :key="subcategory.id">
        <SubCategory :color="color" :subcategory="subcategory" /> 
    </div>
</div>
</template>

<script>
import SubCategory from './SubCategory'
//import json from '../../public/meniu.json'
// import AddSub from './AddSub'
// import Button from './Button'

export default {
    name: 'Category',
    props: {
        category: Number,
        color: String
    },
    components: {
        SubCategory,
        // AddSub,
        // Button
    },
    data() {
        return {
            subcategories: [],
            showAddSub: false,
        }
    },
    methods: {
        async fetchJSON() {
            try {
                const response = await fetch('http://meniu.lafrontiera.ro/meniu.json') // Replace with your server URL
                const json = await response.json()
                this.subcategories = json[this.category].subcategories
            } catch (error) {
                console.error('Error fetching JSON:', error)
            }
        },
    },
    async created() {
        await this.fetchJSON()
        //this.subcategories = json[this.category].subcategories
    }
}

</script>

<style scoped>
.container {
    display: block;
    max-width: 500px;
    margin-left:auto;
    margin-right:auto;
    overflow: auto;
    min-height: 300px;
    height: auto;
    padding: 30px;
    border-radius: 5px;
} 
.fas {
    position: -webkit-sticky;
    position:sticky;
    top:10px;
    left:10px;
    color:#3D405B;
    }
.btn {
        display: inline;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        width: 500px;
        border: 5px solid #3D405B;
    }
</style>