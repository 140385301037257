<template>
    <div class="inainte">
        <!-- <div @click="toggleUpdSub"> -->
        <h2 :style="cssVars" class="title">
            {{  subcategory.name  }} 
            <!-- #{{ subcategory.id }} -->
        </h2>
        <!-- </div> -->
    </div>
        <!-- <UpdSub v-show="showUpdSub" :subcategory="subcategory" />
        <Button class="btn" @click="toggleAddProduct" 
        :text="showAddProduct ? 'Inchide' : 'Adauga produs' "
        :color="showAddProduct ? '#f07167' : '#a1c181' "/>
    <AddProduct v-show="showAddProduct" :subcategory="subcategory.id" /> -->
    <div v-for="product in products" :key="product.id">
        <Product :product="product" :subcategory="subcategory"/>
    </div>
</template>

<script>
import Product from './Product'
// import AddProduct from './AddProduct'
// import Button from './Button'
// import UpdSub from './UpdSub'

export default {
    name: 'SubCategory',
    components: {
        Product,
        // AddProduct,
        // Button,
        // UpdSub,
    },
    props: {
        subcategory: Object,
        color: String,
    },
    computed: {
      cssVars () {
        return{
          '--color': this.color,
        }
    }
    },
    data() {
        return {
            products: [],
            // showAddProduct: false,
            // showUpdSub: false,
        }
    },
    methods: {
        // toggleAddProduct() {
        //     this.showAddProduct = !this.showAddProduct
        // },
        // toggleUpdSub() {
        //     this.showUpdSub = !this.showUpdSub
        // },
    },
    async created() {
        this.products = this.subcategory.products
    },
}
</script>

<style scoped>
    .title {
        text-align: left;
        height: 100%;
        background: var(--color);
        border-left: 5px solid var(--color);
        overflow: hidden;
        left: 1000px;
        /* cursor: pointer; */
    }
    .btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 300px;
        border: 5px solid #3D405B;
    }
    .inainte{
        border-left: 15px solid;
    }
</style>