<template>
    <div @click="toggleUpdProduct" class="product">
        <div class="name">
            {{ product.name }}
            <a href="https://experiencebat.ro/locationbrands?&utm_medium=qr" v-show="product.name == 'OFERTA TIGARI'"> AICI</a>
            <span v-show="product.allergens" class="allergens">*{{ product.allergens }}</span>
            <br>
            <span v-show="product.ingredients">({{ product.ingredients }})</span>
        </div>
        <span v-show="product.price" class="quantity-price">
            {{ product.quantity }} <span class="line">|</span> {{ product.price }} lei
        </span>
        <br>
    </div>
    <!-- <div v-show="showUpdProduct">
    <UpdProduct :product="product" :subcateg="subcategory"/>
    </div> -->
</template>

<script>
// import UpdProduct from "./UpdProduct"

export default {
    name: 'Product',
    components: {
        // UpdProduct,
    },
    props: {
        product: Object,
        subcategory: Object,
    },
    data() {
        return {
            products: [],
            // showUpdProduct: false,
        }
    },
    methods:{
        // toggleUpdProduct() {
        //     this.showUpdProduct = !this.showUpdProduct
        // },
    }
}
</script>

<style scoped>
    .product {
        font-weight: bold;
        max-width: 500px;
        margin: 10px auto;
        height: 100%;
        overflow: hidden;
        padding: 10px;
        border-radius: 0px;
        border-bottom: 5px solid #3D405B;
        /* cursor: pointer; */
    }   
    .name {
        float:left;
        left: 10 px;
        height: auto;
        max-width: 350px;
        text-align: left;
    }
    .quantity-price {
        float:right;
        text-align: right;
    }
    .allergens {
        vertical-align: super;
        font-size: 70%;
    }
    .line {
        color: #3D405B;
    }
</style>