import { createRouter, createWebHashHistory } from 'vue-router'
import Breakfast from '../views/Breakfast.vue'
import Food from '../views/Food.vue'
import Drinks from '../views/Drinks.vue'
import Wines from '../views/Wines.vue'
import Menu from '../views/Menu.vue'

const routes = [
  {
    path: '/',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/micdejun',
    name: 'Breakfast',
    component: Breakfast
  },
  {
    path: '/mancare',
    name: 'Food',
    component: Food
  },
  {
    path: '/bautura',
    name: 'Drinks',
    component: Drinks
  },
  {
    path: '/vinuri',
    name: 'Wines',
    component: Wines
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
