<template>
<Header/>
    <div >
        <router-link v-show="(hour<11 || (hour==11 && minutes<45)) && hour>=7"
            class="link" to="/micdejun"><h1 class="cat"><i class="fas fa-egg fa-5x"/><br>MIC DEJUN</h1></router-link>
        <br>
        <router-link v-show="(hour>=12 || (hour==11 && minutes>=45)) || hour<7"
            class="link" to="/mancare"><h1 class="cat"><i class="fas fa-utensils fa-5x"/><br>MÂNCARE</h1></router-link>
        <br>
        <router-link class="link" to="/bautura"><h1 class="cat"><i class="fas fa-glass-whiskey fa-5x"/><br>BAR</h1></router-link>
        <br>
        <router-link class="link" to="/vinuri"><h1 class="cat"><i class="fas fa-wine-glass fa-5x"/><br>VINURI</h1></router-link>
        <br>
        <a class="link" href="https://nutrimeniu.ro/b/frontiera/m/meniu-principal"><h1 class="cat"><i class="fas fa-info-circle fa-5x"/><br>MENIU DETALIAT<br>VALORI NUTRIȚIONALE</h1></a>
    </div>
</template>

<script>
    import Header from '../components/Header'

    export default {
    name: 'Menu',
    components: {
        Header,
    },
    data() {
        return {
            hour: Number,
        }
    },
    methods: {
        gethour() {
                    const today = new Date();
                    const time = today.getHours()
                    return time
                }
    },
    created() {
        this.hour = this.gethour()
    }
}
</script>

<style>
    .link {
        text-align: center;
        text-decoration: none;
    } 
    .fas {
        display:inline-block;
        margin-left:auto;
        margin-right:auto;
        color: #3D405B;
    }
    .cat {
        color: #3D405B;
    }
</style>