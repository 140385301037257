<template>
  <Header />
  <div class="home"> 
    <Category category=1 color="#F48B7B" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Category from '@/components/Category.vue'

export default {
  name: 'Drinks',
  components: {
    Header,
    Category
  }
}
</script>
