<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #3D405B;
}

body {
	background-image: url("./assets/Fundal.png");
	/* background: linear-gradient(-45deg, #c8ffff, #8cdbff, #f2fcff, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite; */
}

/* @keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */
</style>
