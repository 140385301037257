<template>
    <div id="nav">
        <img src="../assets/LFR.png" height="300" width="300" class="imagine"/>
    </div>
</template>

<style scoped>
    .imagine {
        display:block;
        margin-left: auto;
        margin-right: auto;
    }
</style>